// This is the scss entry file
import '../styles/index.scss';
/* eslint-disable no-unused-vars */


import 'flowbite';
import 'preline';
// import 'htmx.org';
// window.htmx = require('htmx.org');
console.log("Hello from app.js");

